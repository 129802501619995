import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AuthService,
  License,
  LicenseTypeId,
  Membership,
  MembershipStatus,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
  ProfileService,
  Registration,
  ShopifyService,
} from 'src/app/core';
import { FamilyMembershipFacade, RegistrationFacade } from 'src/app/core/facades';
import { NonEnrollmentWaiverModalComponent } from 'src/app/shared/non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';
// import { NonEnrollmentWaiverModalComponent } from 'src/app/shared/non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';
import { environment } from 'src/environments/environment';

import { UpgradeInfoModalComponent } from '../upgrade-info-modal/upgrade-info-modal.component';

@Component({
  selector: 'app-early-renew-upgrade-step',
  templateUrl: './early-renew-upgrade-step.component.html',
  styleUrls: ['./early-renew-upgrade-step.component.scss'],
})
export class EarlyRenewUpgradeStepComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private modal: NgbModal,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private profileService: ProfileService,
    private shopifyService: ShopifyService
  ) { }

  get isExistingUser(): boolean {
    return this.user && !!this.user.profile_id;
  }

  get currentStatusIndicatorProfile(): any {
    return this.isExistingUser ? this.user : this.registrationFacade.currentState.registration;
  }

  get racePlusSelected(): boolean {
    return this.familyFacade.adultHasSpotSubject.getValue();
  }

  get internationalSelected(): boolean {
    return this.registrationFacade.isInternationalSubject.getValue();
  }

  get collegiateSelected(): boolean {
    return this.registrationFacade.isCollegiateSubject.getValue();
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get racePlusPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.racePlusSku].priceV2.amount;
  }

  get internationalPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.internationalSku].priceV2.amount;
  }

  get internationalPlusPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.internationalPlusSku].priceV2.amount;
  }

  get collegiatePrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.raceCollegiate].priceV2.amount;
  }

  get collegiatePlusPrice(): string | number {
    return (
      this.variantsBySku && this.variantsBySku[this.planSkus.racePlusCollegiate].priceV2.amount
    );
  }

  get raceStandardPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.raceSku].priceV2.amount;
  }

  get rideLitePrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.rideLite].priceV2.amount;
  }

  get ridePlusPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.ride].priceV2.amount;
  }

  get raceSku(): string {
    if (this.isJunior) {
      return this.planSkus.junior.race;
    } else {
      return this.planSkus.adult.race;
    }
  }

  get racePlusSku(): string {
    if (this.isJunior) {
      return this.planSkus.junior.racePlus;
    } else {
      return this.planSkus.adult.racePlus;
    }
  }

  get internationalSku(): string {
    if (this.isJunior) {
      return this.planSkus.junior.international;
    } else {
      return this.planSkus.adult.international;
    }
  }

  get internationalPlusSku(): string {
    if (this.isJunior) {
      return this.planSkus.junior.internationalPlus;
    } else {
      return this.planSkus.adult.internationalPlus;
    }
  }

  get isRaceAge18(): boolean {
    return this.user.profile_race_age === 18;
  }

  get hasInternationalLicense(): boolean {
    return this.hasLicense(LicenseTypeId.INTERNATIONAL);
  }

  get hasProLicense(): boolean {
    return this.hasLicense(LicenseTypeId.PROFESSIONAL);
  }

  get hasCollegiateLicense(): boolean {
    return this.hasLicense(LicenseTypeId.COLLEGIATE);
  }

  get loggedIn(): boolean {
    return this.user && !!this.user.profile_id;
  }

  get showLoader(): boolean {
    return this.loggedIn && (this.isLoading || this.isSubmitting);
  }

  get personType(): PersonTypes {
    return this.loggedIn ? this.user.profile_person_type : null;
  }

  get isAdult(): boolean {
    return this.personType === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    if (this.userRaceAge) {
      return this.userRaceAge < 18;
    }
    return false;
  }

  get isAdultPlus(): boolean {
    return this.isAdult && this.familyFacade.adultHasSpot;
  }

  get isJuniorPlus(): boolean {
    return this.isJunior && this.familyFacade.juniorHasSpot;
  }

  get isPlus(): boolean {
    return this.isAdultPlus || this.isJuniorPlus;
  }

  get activeMembership(): Membership {
    return (
      this.allMemberships &&
      this.allMemberships.find(
        (membership) => membership.membership_status === MembershipStatus.Active
      )
    );
  }

  get hasActiveMembership(): boolean {
    return (
      !!this.activeMembership
    );
  }

  get isStandard(): boolean {
    return (
      this.hasActiveMembership && this.activeMembership && this.activeMembership.membership_level === 1 && !this.user.should_show_plus_designation
    );
  }

  get isRide(): boolean {
    return (
      this.hasActiveMembership && this.activeMembership.membership_level === 1 && this.user.should_show_plus_designation
    );
  }

  get isRace(): boolean {
    return (
      this.hasActiveMembership && this.activeMembership.membership_level === 2 && !this.user.should_show_plus_designation
    );
  }

  get isRacePlus(): boolean {
    return (
      this.hasActiveMembership && this.activeMembership.membership_level === 2 && this.user.should_show_plus_designation
    );
  }

  get upgradeOptions(): any[] {
    let key;
    if (this.isStandard) {
      key = 'standard';
    } else if (this.isRide) {
      key = 'ridePlus';
    } else if (this.isRacePlus) {
      if (this.hasInternationalLicense) {
        key = 'racePlusInternational';
      } else if (this.hasCollegiateLicense) {
        key = 'racePlusCollegiate';
      } else {
        key = 'racePlus';
      }
    } else if (this.isRace) {
      if (this.hasInternationalLicense) {
        key = 'international';
      } else if (this.hasCollegiateLicense) {
        key = 'collegiate';
      } else {
        key = 'race';
      }
    } else {
      key = '';
    }

    // const key = this.isRacePlus ? 'racePlus' : 'race';
    let options = this.upgradeOptionsMap[key];

    if (this.hasCollegiateLicense) {
      options = options.filter((option: any) => option.type !== 'collegiate');
      if (this.isRace) {
        options.find((option: any) => option.type === 'race-plus').hideAutoRenew = true;
      }
    }

    if (this.hasInternationalLicense || this.hasProLicense) {
      options = options.filter((option: any) => option.type !== 'international');
    }

    if (this.isRace && this.hasInternationalLicense) {
      options.find((option: any) => option.type === 'race-plus').title = 'International+ Add On';
    }

    return options;
  }

  get profileLicenses(): License[] {
    return this.user?.licenses;
  }
  user: Profile;
  userRaceAge: number;
  isLoading = true;
  userLoaded = false;
  statusIndicatorFirstName = '';
  statusIndicatorLastName = '';
  statusIndicatorEmail = '';
  allMemberships: Membership[];
  membershipsLoading = true;
  membershipLookupStarted = false;
  isSubmitting = false;
  serverError: any = null;
  registration: Registration;
  registrationId: string;

  raceDependenciesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  variantsBySku: { [key: string]: ProductVariant };
  collegiateSku = 'COL-AO';
  upgradeSku: string;
  waiverSigned: boolean;
  racePlusIsSelected = false;
  internationalIsSelected = false;
  collegiateIsSelected = false;

  ridePlusBenefits = ['24/7 on-the-the bike coverage'];
  raceBenefits = ['Race in your category in all disciplines'];
  racePlusBenefits = ['Includes Race License', '24/7 on-the-the bike coverage'];
  collegiateBenefits = ['Discounted Race License', 'Access to Collegiate races'];
  internationalBenefits = ['Required for UCI races', 'Required for International racing'];
  internationalPlusBenefits = [
    'Required for UCI & International racing',
    '24/7 on-the-the bike coverage',
  ];

  planSkus = this.shopifyService.planSkus;

  upgradeOptionsMap: any;

  ngOnInit(): void {
    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }

          if (profile && profile.profile_id) {
            this.user = profile;
            this.userRaceAge = profile.profile_race_age;
            this.statusIndicatorFirstName = profile.profile_first_name;
            this.statusIndicatorLastName = profile.profile_last_name;
            this.statusIndicatorEmail = profile.profile_email;

            if (!this.membershipLookupStarted) {
              this.membershipLookupStarted = true;
              this.profileService
                .getMemberships(profile.profile_id)
                .subscribe((memberships: Membership[]) => {
                  if (memberships) {
                    this.allMemberships = memberships;
                    this.setUpgradeCards();
                  }
                  this.membershipsLoading = false;
                  this.setPrices();
                });
            }
          } else {
            this.membershipsLoading = false;
          }
          // this.subscribeToDemographics();

          this.userLoaded = true;
        })
      )
      .subscribe();
    this.planSkus = this.shopifyService.planSkus;
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
  }

  setUpgradeCards(): void {
    // commented out options are for MBR-2999
    this.upgradeOptionsMap = {
      ride: [
        {
          title: 'Race+',
          type: 'ride-race-plus',
          price: this.racePlusPrice,
          perYear: true,
          sku: this.racePlusSku,
          benefits: this.racePlusBenefits,
          boldBorder: true,
          spotItem: true,
          hideAutoRenew: false,
        },
      ],
      race: [
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          hideAutoRenew: false,
          isCurrentMembership: true,
          isEarlyRenewal: true,
        },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   hideAutoRenew: false,
        //   isEarlyRenewal: true,
        // },
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
        },
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isSelected: this.internationalIsSelected,
        },
      ],
      collegiate: [
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
          isCurrentMembership: true,
        },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          isEarlyRenewal: true,
        },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isEarlyRenewal: true,
        // },
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isSelected: this.internationalIsSelected,
        },
      ],
      international: [
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isCurrentMembership: true,
          isSelected: this.internationalIsSelected,
        },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          hideAutoRenew: false,
          isEarlyRenewal: true,
        },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isEarlyRenewal: true,
        // },
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
        },
      ],
      racePlus: [
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isCurrentMembership: true,
        //   isEarlyRenewal: true,
        // },
        // {
        //   title: 'Collegiate+ License',
        //   type: 'collegiate',
        //   price: this.collegiatePlusPrice,
        //   perYear: true,
        //   sku: this.planSkus.racePlusCollegiate,
        //   benefits: this.collegiateBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        // },
        // {
        //   title: 'International+ License',
        //   type: 'international',
        //   price: this.internationalPlusPrice,
        //   sku: this.internationalPlusSku,
        //   perYear: false,
        //   benefits: this.internationalPlusBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        //   isSelected: this.internationalIsSelected,
        // },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          hideAutoRenew: false,
          isEarlyRenewal: true,
        },
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
        },
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isSelected: this.internationalIsSelected,
        },
      ],
      collegiatePlus: [
        // {
        //   title: 'Collegiate+ License',
        //   type: 'collegiate',
        //   price: this.collegiatePlusPrice,
        //   perYear: true,
        //   sku: this.planSkus.racePlusCollegiate,
        //   benefits: this.collegiateBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        //   isCurrentMembership: true,
        // },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isEarlyRenewal: true,
        // },
        // {
        //   title: 'International+ License',
        //   type: 'international',
        //   price: this.internationalPlusPrice,
        //   sku: this.internationalPlusSku,
        //   perYear: false,
        //   benefits: this.internationalPlusBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        //   isSelected: this.internationalIsSelected,
        // },
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
        },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          isEarlyRenewal: true,
        },
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isSelected: this.internationalIsSelected,
        },
      ],
      internationalPlus: [
        // {
        //   title: 'International+ License',
        //   type: 'international',
        //   sku: this.internationalPlusSku,
        //   price: this.internationalPlusPrice,
        //   perYear: false,
        //   benefits: this.internationalPlusBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        //   isSelected: this.internationalIsSelected,
        //   isCurrentMembership: true,
        // },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isEarlyRenewal: true,
        // },
        // {
        //   title: 'Collegiate+ License',
        //   type: 'collegiate',
        //   price: this.collegiatePlusPrice,
        //   perYear: true,
        //   sku: this.planSkus.racePlusCollegiate,
        //   benefits: this.collegiateBenefits,
        //   boldBorder: false,
        //   spotItem: false,
        // },
        {
          title: 'International License',
          type: 'international',
          price: this.internationalPrice,
          sku: this.internationalSku,
          perYear: false,
          benefits: this.internationalBenefits,
          boldBorder: false,
          spotItem: false,
          isCurrentMembership: true,
          isSelected: this.internationalIsSelected,
        },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          hideAutoRenew: false,
          isEarlyRenewal: true,
        },
        {
          title: 'Collegiate License Upgrade',
          type: 'collegiate',
          price: this.collegiatePrice,
          sku: this.planSkus.raceCollegiate,
          perYear: true,
          benefits: this.collegiateBenefits,
          boldBorder: false,
          spotItem: false,
        },
      ],
      standard: [
        {
          title: 'Ride',
          type: 'ride',
          price: this.rideLitePrice,
          sku: this.planSkus.rideLite,
          border: true,
          spotItem: false,
          isCurrentMembership: true,
        },
        // {
        //   title: 'Ride+',
        //   type: 'ride-plus',
        //   price: this.ridePlusPrice,
        //   sku: this.planSkus.ride,
        //   benefits: this.ridePlusBenefits,
        //   border: true,
        //   spotItem: true,
        // },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
          isEarlyRenewal: true,
        },
      ],
      ridePlus: [
        {
          title: 'Ride',
          type: 'ride',
          price: this.rideLitePrice,
          sku: this.planSkus.rideLite,
          border: true,
          spotItem: false,
          isCurrentMembership: true,
        },
        // {
        //   title: 'Ride+',
        //   type: 'ride-plus',
        //   price: this.ridePlusPrice,
        //   sku: this.planSkus.ride,
        //   benefits: this.ridePlusBenefits,
        //   border: true,
        //   spotItem: true,
        //   isCurrentMembership: true,
        // },
        {
          title: 'Race',
          type: 'race-standard',
          price: this.raceStandardPrice,
          sku: this.raceSku,
          benefits: this.raceBenefits,
          border: true,
          spotItem: false,
        },
        // {
        //   title: 'Race+',
        //   type: 'race-plus',
        //   price: this.racePlusPrice,
        //   sku: this.racePlusSku,
        //   perYear: true,
        //   benefits: this.racePlusBenefits,
        //   boldBorder: true,
        //   spotItem: true,
        //   isEarlyRenewal: true,
        // },
      ],
    };
    // if the user has a membership that early renews before the end of the year, or todays date is 01/01/2024 we add plus products if neccessary
    this.checkMembershipExpiration();
  }

  checkMembershipExpiration(): void {
    const today = new Date();
    const firstDayOfNextYear = new Date('2024-01-01T00:00:00.000-05:00');
    const membershipExpirationDate = new Date(this.activeMembership.membership_expiration_date);
    if (
      today.getTime() >= firstDayOfNextYear.getTime() ||
      membershipExpirationDate.getTime() < firstDayOfNextYear.getTime()
    ) {
      if (this.isStandard) {
        this.upgradeOptionsMap.standard.push({
          title: 'Ride+',
          type: 'ride-plus',
          price: this.ridePlusPrice,
          sku: this.planSkus.ride,
          benefits: this.ridePlusBenefits,
          border: true,
          spotItem: true,
        });
      } else if (this.isRide) {
        this.upgradeOptionsMap.ridePlus = [
          {
            title: 'Ride+',
            type: 'ride-plus',
            price: this.ridePlusPrice,
            sku: this.planSkus.ride,
            benefits: this.ridePlusBenefits,
            border: true,
            spotItem: true,
            isCurrentMembership: true,
          },
          {
            title: 'Race',
            type: 'race-standard',
            price: this.raceStandardPrice,
            sku: this.raceSku,
            benefits: this.raceBenefits,
            border: true,
            spotItem: false,
          },
          {
            title: 'Race+',
            type: 'race-plus',
            price: this.racePlusPrice,
            sku: this.racePlusSku,
            perYear: true,
            benefits: this.racePlusBenefits,
            boldBorder: true,
            spotItem: true,
            isEarlyRenewal: true,
          },
        ];
      } else if (this.isRacePlus) {
        if (this.hasInternationalLicense) {
          this.upgradeOptionsMap.internationalPlus = [
            {
              title: 'International+ License',
              type: 'international',
              sku: this.internationalPlusSku,
              price: this.internationalPlusPrice,
              perYear: false,
              benefits: this.internationalPlusBenefits,
              boldBorder: false,
              spotItem: false,
              isSelected: this.internationalIsSelected,
              isCurrentMembership: true,
            },
            {
              title: 'Race+',
              type: 'race-plus',
              price: this.racePlusPrice,
              sku: this.racePlusSku,
              perYear: true,
              benefits: this.racePlusBenefits,
              boldBorder: true,
              spotItem: true,
              isEarlyRenewal: true,
            },
            {
              title: 'Collegiate+ License',
              type: 'collegiate',
              price: this.collegiatePlusPrice,
              perYear: true,
              sku: this.planSkus.racePlusCollegiate,
              benefits: this.collegiateBenefits,
              boldBorder: false,
              spotItem: false,
            },
          ];
        } else if (this.hasCollegiateLicense) {
          this.upgradeOptionsMap.collegiatePlus = [
            {
              title: 'Collegiate+ License',
              type: 'collegiate',
              price: this.collegiatePlusPrice,
              perYear: true,
              sku: this.planSkus.racePlusCollegiate,
              benefits: this.collegiateBenefits,
              boldBorder: false,
              spotItem: false,
              isCurrentMembership: true,
            },
            {
              title: 'Race+',
              type: 'race-plus',
              price: this.racePlusPrice,
              sku: this.racePlusSku,
              perYear: true,
              benefits: this.racePlusBenefits,
              boldBorder: true,
              spotItem: true,
              isEarlyRenewal: true,
            },
            {
              title: 'International+ License',
              type: 'international',
              price: this.internationalPlusPrice,
              sku: this.internationalPlusSku,
              perYear: false,
              benefits: this.internationalPlusBenefits,
              boldBorder: false,
              spotItem: false,
              isSelected: this.internationalIsSelected,
            },
          ];
        } else {
          this.upgradeOptionsMap.racePlus = [
            {
              title: 'Race+',
              type: 'race-plus',
              price: this.racePlusPrice,
              sku: this.racePlusSku,
              perYear: true,
              benefits: this.racePlusBenefits,
              boldBorder: true,
              spotItem: true,
              isCurrentMembership: true,
              isEarlyRenewal: true,
            },
            {
              title: 'Collegiate+ License',
              type: 'collegiate',
              price: this.collegiatePlusPrice,
              perYear: true,
              sku: this.planSkus.racePlusCollegiate,
              benefits: this.collegiateBenefits,
              boldBorder: false,
              spotItem: false,
            },
            {
              title: 'International+ License',
              type: 'international',
              price: this.internationalPlusPrice,
              sku: this.internationalPlusSku,
              perYear: false,
              benefits: this.internationalPlusBenefits,
              boldBorder: false,
              spotItem: false,
              isSelected: this.internationalIsSelected,
            },
          ];
        }
      } else if (this.isRace) {
        if (this.hasInternationalLicense) {
          this.upgradeOptionsMap.international.push({
            title: 'Race+',
            type: 'race-plus',
            price: this.racePlusPrice,
            sku: this.racePlusSku,
            perYear: true,
            benefits: this.racePlusBenefits,
            boldBorder: true,
            spotItem: true,
            isEarlyRenewal: true,
          });
        } else if (this.hasCollegiateLicense) {
          this.upgradeOptionsMap.collegiate.push({
            title: 'Race+',
            type: 'race-plus',
            price: this.racePlusPrice,
            sku: this.racePlusSku,
            perYear: true,
            benefits: this.racePlusBenefits,
            boldBorder: true,
            spotItem: true,
            isEarlyRenewal: true,
          });
        } else {
          this.upgradeOptionsMap.race.push({
            title: 'Race+',
            type: 'race-plus',
            price: this.racePlusPrice,
            sku: this.racePlusSku,
            perYear: true,
            benefits: this.racePlusBenefits,
            boldBorder: true,
            spotItem: true,
            isEarlyRenewal: true,
          });
        }
      }
    }
  }

  selectUpgrade(item: any): void {
    const { type } = item;
    switch (type) {
      case 'collegiate':
      case 'international':
        this.openUpgradeInfoModal(item);
        break;
      default:
        this.openWaiverModal('online_waiver', item);
    }
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  private setPrices(): void {
    this.upgradeOptions.forEach((item) => {
      if (!item.price && item.sku) {
        item.price = this.variantsBySku[item.sku].priceV2.amount;
      }
    });
    this.isLoading = false;
  }

  private hasLicense(licenseType: LicenseTypeId): boolean {
    const now = new Date();
    const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);

    return (
      !!this.profileLicenses &&
      this.profileLicenses.some(
        (license) =>
          Date.parse(license.expiration_date) >= today && license.license_type === licenseType
      )
    );
  }

  openUpgradeInfoModal(item: any): void {
    const { title, type } = item;
    const modalRef = this.modal.open(UpgradeInfoModalComponent, {
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.isUpgrade = true;

    modalRef.result
      .then(() => {
        if (type === 'international') {
          this.openWaiverModal('international_waiver', item);
        } else {
          this.openWaiverModal('online_waiver', item);
        }
      })
      .catch(() => { });
  }

  openWaiverModal(waiverName: string, item: any): void {
    if (!this.ghostModeActive) {
      const modal = this.modal.open(NonEnrollmentWaiverModalComponent, {
        centered: true,
        size: 'xl',
      });
      modal.componentInstance.user = this.user;
      modal.componentInstance.waiverName = waiverName;
      modal.result
        .then(() => {
          this.waiverSigned = true;
          this.checkout(item);
        })
        .catch(() => { });
    } else {
      this.checkout(item);
    }
  }

  private checkout(item: any): void {
    this.isSubmitting = true;

    const lineItem: any = {
      variant: this.variantsBySku[item.sku],
      quantity: 1,
    };

    lineItem.customAttributes = [
      {
        key: 'recurring',
        value: 'None',
      },
      {
        key: 'upgrade',
        value: item.isCurrentMembership,
      },
    ];

    const lineItems: Array<any> = [lineItem];
    // const note = `membership, ${type}-upgrade, ${MerchantAccountTag.Membership}`;

    const strigifiedLineItems = JSON.stringify(lineItems);
    const encodedLineItems = btoa(strigifiedLineItems);

    window.location.href = `${environment.checkoutUrl}/confirmation/billing/${encodedLineItems}`;
  }
}
